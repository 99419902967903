

























































































































































import { networks } from '@/constants'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { marketViewModel } from '../viewmodels/market-viewmodel'
@Observer
@Component({
  components: {},
})
export default class OpenMarket extends Vue {
  vm = marketViewModel

  validate = false
  networks = networks
  vestingDurations = [
    {
      title: 'Short-term (3-6 months)',
      value: 'short',
    },
    {
      title: 'Mid-term (6-12 months)',
      value: 'mid',
    },
    {
      title: 'Long-term (over 12 months)',
      value: 'long',
    },
  ]
}
